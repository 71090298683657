.containerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px;
  gap: 20px;
}
.headerTextViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.headerStyle {
  font-size: 30px;
  font-weight: 800;
  line-height: 34px;
  color: black;
}
.listViewStyle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}
.cardViewStyle {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rebeccapurple;
  border-radius: 20px;
  gap: 20px;
}
.titleStyle {
  margin: 0px;
}
.imageViewStyle {
  width: 250px;
  height: 200px;
}
.imageStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.btnStyle {
  width: fit-content;
  padding: 8px 16px;
  background-color: lightblue;
  outline: none;
  border: 1px solid;
  border-radius: 20px;
  color: black;
  cursor: pointer;
}
